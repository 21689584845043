const PalletsInTrailer = ({ loading, palletsInTrailer }) => {
  const renderPalletsInTrailerTable = (data) => (
    <table className="pallets-table">
      <tr className="pallets-table-header-section">
        {["Position In Trailer", "SKU", "LPN"].map((header, index) => (
          <th key={index} className="pallets-table-header">
            {header}
          </th>
        ))}
      </tr>
      {data?.map(({ POSITION, SKU, LPN }, index) => (
        <tr key={index} className="pallets-table-data">
          {[POSITION, SKU, LPN].map((pallet, index) => (
            <td key={index} className="pallets-table-row-data">
              {pallet ?? "-"}
            </td>
          ))}
        </tr>
      ))}
    </table>
  );

  return (
    <div className="detail-section-container">
      <div className="additional-data-section-title extra-bottom-margin">
        <h3>Pallets in Trailer</h3>
      </div>
      {loading ? (
        <div className="dock-spinner-wrapper">
          <h4>Fetching Pallets in Trailer...</h4>
          <div className="spinner"></div>
        </div>
      ) : palletsInTrailer.length !== 0 ? (
        <div className="details-wrapper details-wrapper-scroller">
          {renderPalletsInTrailerTable(palletsInTrailer)}
        </div>
      ) : null}
    </div>
  );
};

export default PalletsInTrailer;
