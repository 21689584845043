import React, { useEffect, useState } from "react";
import "../../assets/css/Status/status.css";
import {
  getLocalStorageItem,
  onDragging,
  onDraggingEnd,
  statusColorRenderer,
} from "../../util/helper/helperFunctions";
import { DB_ERROR } from "../../util/helper/constants";
const Status = ({ title, scrollable, socket }) => {
  const [statusLoader, setStatusLoader] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    let auth = getLocalStorageItem("auth");
    let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
    if (auth !== null && locationCodeLocal) {
      const initiateShipmentWebSocket = () => {
        socket.on(`${locationCodeLocal}_SHIPMENT_STATUS`, (data) => {
          if (
            data.results &&
            data.results !== undefined &&
            data.results !== DB_ERROR
          ) {
            setData(data.results);
            setStatusLoader(false);
          }
        });
      };
      initiateShipmentWebSocket();
    }
  }, [socket, getLocalStorageItem("locationCode")]);

  return (
    <div
      onMouseDown={(event) => onDragging(event)}
      onMouseUp={(event) => onDraggingEnd(event)}
      className="status-container"
    >
      <div className="header-section">
        <h4>{title}</h4>
      </div>
      {statusLoader ? (
        <div
          className={`status-section loader-alignment ${
            scrollable ? "status-section-wrap" : ""
          } `}
        >
          <div className="rm-loader">
            <h4>Loading...</h4>
            <div className="spinner"></div>
          </div>
        </div>
      ) : (
        <div
          className={`status-section ${
            scrollable ? "status-section-wrap" : ""
          }`}
        >
          {data &&
            data.map((status, index) => {
              return Object.entries(status).map(([key, value]) => {
                return (
                  <div
                    key={index}
                    style={{
                      boxShadow: `0px 6px 12px 0px ${statusColorRenderer(
                        key
                      )}20`,
                    }}
                    className="status-box"
                  >
                    <div
                      style={
                        key == "Started"
                          ? { backgroundColor: `#EEEEEE` }
                          : { backgroundColor: `${statusColorRenderer(key)}` }
                      }
                      className="status-top-section"
                    >
                      <p
                        style={
                          key === "Started"
                            ? { color: "black" }
                            : { color: "white" }
                        }
                      >
                        {key}
                      </p>
                    </div>
                    <div className="status-bottom-section">
                      <p>{value}</p>
                    </div>
                  </div>
                );
              });
            })}
        </div>
      )}
    </div>
  );
};

export default Status;
