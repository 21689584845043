import check from "../../../assets/icons/check.png";
import error from "../../../assets/icons/error.png";

const labelMapping = {
  doorOperation: {
    0: <p className="auto-text-tag">Auto</p>,
    1: <p className="manual-text-tag">Manual</p>,
  },
  normal: {
    0: <p className="invalid-text-tag">No</p>,
    1: <p className="valid-text-tag">Yes</p>,
  },
};

const DockDoorStatus = ({ loading, dockDoorStatus }) => {
  const getTextCardValue = (label, data) =>
    data !== undefined ? labelMapping[label][data] : null;

  const renderCardStatus = ({ lgvInside, doorOpen, trailerAtDoor }) => (
    <div className="status-card-status-wrapper">
      {[
        { isActive: lgvInside === 1, label: "LGV Inside" },
        { isActive: doorOpen === 1, label: "Door Open" },
        { isActive: trailerAtDoor === 1, label: "Trailer At Door" },
      ].map(({ isActive, label }) => (
        <div key={label} className="status-card">
          <img src={isActive ? check : error} alt={label} />
          <p>{label}</p>
        </div>
      ))}
    </div>
  );

  const renderTextCardStatus = ({
    readyForLoading,
    doorOperation,
    airbagHold,
    fault,
  }) => (
    <div className="status-card-status-wrapper">
      {[
        {
          label: "Ready for Loading",
          value: getTextCardValue("normal", readyForLoading),
        },
        {
          label: "Door Operation",
          value: getTextCardValue("doorOperation", doorOperation),
        },
        { label: "Airbag Hold", value: getTextCardValue("normal", airbagHold) },
        { label: "Fault", value: getTextCardValue("normal", fault) },
      ].map(({ label, value }) => (
        <div key={label} className="status-card-text">
          <p>{label}</p>
          {value}
        </div>
      ))}
    </div>
  );

  return (
    <div className="detail-section-container column-section">
      <div className="additional-data-section-title">
        <h3>Dock Door Status</h3>
      </div>
      {loading ? (
        <div className="dock-spinner-wrapper">
          <h4>Fetching Dock Door Status...</h4>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="details-wrapper">
          {Object.keys(dockDoorStatus).length ? (
            <>
              {renderCardStatus(dockDoorStatus)}
              {renderTextCardStatus(dockDoorStatus)}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default DockDoorStatus;
