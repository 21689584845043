import React, { useEffect, useState } from "react";
import "../../assets/css/Status/activeShipment.css";
import {
  getLocalStorageItem,
  onDragging,
  onDraggingEnd,
} from "../../util/helper/helperFunctions";
import { DB_ERROR } from "../../util/helper/constants";
const ActiveShipment = ({ title, scrollable, socket }) => {
  const [statusLoader, setStatusLoader] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    let auth = getLocalStorageItem("auth");
    let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
    if (auth !== null && locationCodeLocal) {
      const initiateActiveShipmentWebSocket = () => {
        socket.on(`${locationCodeLocal}_SHIPMENT_DETAILS`, (data) => {
          if (
            data.results &&
            data.results !== undefined &&
            data.results !== DB_ERROR
          ) {
            setData(data.results);
            setStatusLoader(false);
          }
        });
      };
      initiateActiveShipmentWebSocket();
    }
  }, [socket, getLocalStorageItem("locationCode")]);

  return (
    <div
      onMouseDown={(event) => onDragging(event)}
      onMouseUp={(event) => onDraggingEnd(event)}
      id="active-shipment-status-container"
      className="active-shipment-status-container"
    >
      <div className="active-shipment-header-section">
        <h4>{title}</h4>
      </div>
      {statusLoader ? (
        <div
          className={`active-shipment-status-section active-shipment-loader-alignment ${
            scrollable ? "active-shipment-status-section-wrap" : ""
          } `}
        >
          <div className="rm-loader">
            <h4>Loading...</h4>
            <div className="spinner"></div>
          </div>
        </div>
      ) : (
        <div
          className={`active-shipment-status-section ${
            scrollable ? "active-shipment-status-section-wrap" : ""
          } `}
        >
          {data &&
            data.map((status, index) => {
              return Object.entries(status).map(([key, value]) => {
                return (
                  <div key={index} className="active-shipment-status-box">
                    <div className="active-shipment-status-top-section">
                      <p>{value ? value : "-"}</p>
                    </div>
                    <div className="active-shipment-status-bottom-section">
                      <p>{key}</p>
                    </div>
                  </div>
                );
              });
            })}
        </div>
      )}
    </div>
  );
};

export default ActiveShipment;
