import { useEffect, useRef } from "react";
import { Popup } from "react-leaflet";

const CustomPopup = ({ children, offSet, ...props }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const popup = popupRef.current;
    if (popup && popup.options) {
      if (offSet) {
        popup.options.offset = offSet;
      }
    }
  }, [offSet]);

  return (
    <Popup ref={popupRef} {...props}>
      {children}
    </Popup>
  );
};

export default CustomPopup;
