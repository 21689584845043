import { useEffect, useState } from "react";
import "../../assets/css/RackFrontViewGrid/rackFrontViewGridStyles.css";

const isVerticalPallets = (data) =>
  data?.every(
    ({ rackType, row }) => rackType === "RACK_2" || row === data?.[0].row
  );

const RackFrontViewGrid = ({ locationData }) => {
  const [palletData, setPalletData] = useState([]);

  useEffect(() => {
    if (locationData) {
      const shortenedLocation = locationData?.map(
        ({
          location,
          lpn,
          quantity,
          rackType,
          row,
          sku,
          locationType,
          palletPosition,
        }) => {
          if (locationType.includes("Double Deep")) {
            row = palletPosition;
          }
          return {
            location,
            lpn,
            quantity,
            rackType,
            row,
            sku,
          };
        }
      );
      const groupByLocation = Object.groupBy(
        shortenedLocation,
        ({ location }) => location
      );

      const sortedLocations = Object.keys(groupByLocation).sort(
        (palletA, palletB) => palletB.localeCompare(palletA)
      );

      const modifiedPalletData = sortedLocations
        .map((location) => groupByLocation[location])
        .flat();

      setPalletData(modifiedPalletData);
    }
  }, [locationData]);

  return (
    <div
      data-testid="non-lgv-rack-grid"
      className={`pallet-card-container${
        isVerticalPallets(palletData) ? " flex-column" : ""
      }`}
      style={{
        gridTemplateColumns: `repeat(${Math.max(
          ...palletData?.map(({ row }) => row)
        )}, 1fr)`,
      }}
    >
      {palletData?.map((pallet, index) => (
        <PalletCard key={index} pallet={pallet} />
      ))}
    </div>
  );
};

export default RackFrontViewGrid;

const PalletCard = ({ pallet: { lpn, sku, quantity, location, row } }) => {
  const details = [
    { label: "LPN", value: lpn },
    { label: "SKU", value: sku },
    { label: "Quantity", value: quantity },
  ];

  return (
    <div
      className={`${!lpn && !sku && !quantity ? "invisible " : ""}pallet-card`}
    >
      <p className="pallet-value">{location}&nbsp; </p>
      <p style={{ margin: 0 }}>Position {row}</p>
      {details.map(({ label, value }, index) => (
        <div key={index} className="pallet-details-container">
          <p className="pallet-label">{label}</p>
          <p className="pallet-value">{value}</p>
        </div>
      ))}
    </div>
  );
};
