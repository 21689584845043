import {
  dockDoorStatusColor,
  getLocalStorageItem,
} from "../../../util/helper/helperFunctions";

const DockDoorTopView = ({ loading, dockDoorNumber, palletsInDockDoor }) => {
  const dockMode = getLocalStorageItem("dockMode");
  const renderPallets = (value) => {
    const [firstPallet, secondPallet] = value;
    const { SKU, STATUS } = firstPallet;
    const backgroundColor = dockDoorStatusColor(
      STATUS,
      dockMode ? "COMPLEX" : "SIMPLE"
    );

    if (secondPallet) {
      return (
        <div className="dock dock-popup-big">
          {value.map(({ STATUS, SKU }, index) => (
            <div
              key={index}
              style={{ backgroundColor }}
              className={`dock-popup dock-popup-big-complex-${
                !index ? "left" : "right"
              }`}
            >
              <h4>
                {SKU}
                <br />
                {STATUS}
              </h4>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div
          style={{ backgroundColor }}
          className="dock-popup dock-popup-small"
        >
          <h4>
            {SKU}
            <br />
            {STATUS}
          </h4>
        </div>
      );
    }
  };

  return (
    <div className="dock-door-detail-right-section">
      <div className="dock-door-popup">
        <div className="dock-door-popup-container">
          <div className="dock-popup-top-section">
            {dockDoorNumber ? dockDoorNumber : "Loading..."}
          </div>
          {loading ? (
            <div className="rm-loader">
              <h4>Loading...</h4>
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="dock-popup-bottom-section">
              {palletsInDockDoor &&
                Object.values(palletsInDockDoor).map((value) =>
                  renderPallets(value)
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DockDoorTopView;
