import React, { useMemo } from "react";
import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import {
  mapCoordinate,
  convertReactComponentToHtmlIcon,
  renderPreFilledDoubleBlockPallet,
  renderPreFilledSingleBlockPallet,
  findIsLocationVertical,
  renderRackPallet,
  getBlockRackDataWithLocationOrientation,
  getLocationOrientationOfStorage,
  getLocalStorageItem,
  formatName,
  setBlockStorageSize,
  setVerticalBlockStorageSize,
  countBlocksUsingLPNs,
} from "../../util/helper/helperFunctions";

const RenderBlockStorage = ({ plantConfig, blockData, mapObject }) => {
  const getLocationGroupOffset = (convertedValue, offsetObject) => {
    if (convertedValue && offsetObject) {
      //Offset Object
      let offsetCoordinates = {
        x: convertedValue.lat + offsetObject.x,
        y: convertedValue.lng + offsetObject.y,
      };
      //Offset Array
      let offsetCoordinateArray = [offsetCoordinates.x, offsetCoordinates.y];
      return offsetCoordinateArray;
    }
  };

  const getMetaDataOfLocation = (location, type) => {
    if (type === "block") {
      return {
        latitude: location.latitude,
        longitude: location.longitude,
        location: location.location,
        totalPositions: location.totalPositions,
        positionTransition: location.positionTransition,
        positionOrientation: location.positionOrientation,
        locationOrientation: location.locationOrientation,
        positionLength: location.positionLength,
      };
    } else {
      return {
        latitude: location.latitude,
        longitude: location.longitude,
        location: location.location,
        totalPositions: location.totalPositions,
        locationOrientation: location.locationOrientation,
        positionLength: location.positionLength,
      };
    }
  };

  const isBlockObjectValid = (obj) => {
    // Check if the object and index exist
    if (obj) {
      let firstLocationPalletIndex = Object.keys(obj)[0];
      let firstObjectPalletValue = obj[firstLocationPalletIndex];

      let firstPalletLevelIndex = Object.keys(firstObjectPalletValue)[0];
      let firstPalletLevelValue =
        firstObjectPalletValue[firstPalletLevelIndex][0];

      if (firstPalletLevelValue !== undefined) {
        return firstPalletLevelValue;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const markerSectionGenerator = (
    locationsList,
    transitionPallet,
    doubleRows,
    pbcRows,
    totalPositions,
    locationMetaData
  ) => {
    let sectionList = [];

    //Rack List
    let racksList = [];

    //Block List
    //Single Column List
    let blockSingleColumnList = [];

    //Double Rows List
    let doubleList1 = [];
    let doubleList2 = [];
    let doubleList3 = [];
    let doubleList4 = [];

    //PBC Rows List
    let pbcList1 = [];
    let pbcList2 = [];
    let pbcList3 = [];
    let pbcList4 = [];

    if (transitionPallet === undefined) {
      //Racks
      Object.values(locationsList).forEach((location) => {
        racksList.push(location);
      });

      sectionList.push(racksList);
    } else {
      //Blocks
      //Double Rows
      let totalDoubleRows = doubleRows;
      //Pallets for Double Rows
      let palletsInDoubleRow =
        (totalPositions / 2 - transitionPallet) / totalDoubleRows;

      //PBC Rows
      let totalPbcRows = pbcRows;
      //PBC Rows Start Pallet Index
      let pbcRowsStartPalletIndex = totalPositions / 2 - transitionPallet;
      //Pallets for Pbc Rows
      let palletsInPbcRow =
        (totalPositions / 2 - transitionPallet) / totalPbcRows;

      Object.values(locationsList).forEach((location, index) => {
        if (transitionPallet === null) {
          blockSingleColumnList.push(location);
        } else {
          if (index <= palletsInDoubleRow * totalDoubleRows) {
            // console.log("index", index);
            if (totalDoubleRows === 1) {
              if (index <= palletsInDoubleRow - 1) {
                doubleList1.push(location);
              }
            } else if (totalDoubleRows === 2) {
              if (index <= palletsInDoubleRow - 1) {
                doubleList1.push(location);
              } else if (
                index >= palletsInDoubleRow &&
                index <= palletsInDoubleRow * 2 - 1
              ) {
                doubleList2.push(location);
              }
            } else if (totalDoubleRows === 3) {
              if (index <= palletsInDoubleRow - 1) {
                doubleList1.push(location);
              } else if (
                index >= palletsInDoubleRow &&
                index <= palletsInDoubleRow * 2 - 1
              ) {
                doubleList2.push(location);
              } else if (
                index >= palletsInDoubleRow * 2 &&
                index <= palletsInDoubleRow * 3 - 1
              ) {
                doubleList3.push(location);
              }
            } else if (totalDoubleRows === 4) {
              if (index <= palletsInDoubleRow - 1) {
                doubleList1.push(location);
              } else if (
                index >= palletsInDoubleRow &&
                index <= palletsInDoubleRow * 2 - 1
              ) {
                doubleList2.push(location);
              } else if (
                index >= palletsInDoubleRow * 2 &&
                index <= palletsInDoubleRow * 3 - 1
              ) {
                doubleList3.push(location);
              } else if (
                index >= palletsInDoubleRow * 3 &&
                index <= palletsInDoubleRow * 4 - 1
              ) {
                doubleList4.push(location);
              }
            }
          } else {
            if (totalPbcRows === 1) {
              if (index > pbcRowsStartPalletIndex - 1) {
                pbcList1.push(location);
              }
            } else if (totalPbcRows === 2) {
              if (
                index > pbcRowsStartPalletIndex &&
                index <= pbcRowsStartPalletIndex + transitionPallet / 2
              ) {
                pbcList1.push(location);
              } else if (
                index >
                pbcRowsStartPalletIndex + transitionPallet / 2
              ) {
                pbcList2.push(location);
              }
            } else if (totalPbcRows === 3) {
              if (
                index > pbcRowsStartPalletIndex &&
                index <= pbcRowsStartPalletIndex + transitionPallet / 2
              ) {
                pbcList1.push(location);
              } else if (
                index > pbcRowsStartPalletIndex + transitionPallet / 2 &&
                index <= pbcRowsStartPalletIndex + transitionPallet
              ) {
                pbcList2.push(location);
              } else if (
                index > pbcRowsStartPalletIndex + transitionPallet &&
                index <=
                  pbcRowsStartPalletIndex +
                    transitionPallet +
                    transitionPallet / 2
              ) {
                pbcList3.push(location);
              }
            } else if (totalPbcRows === 4) {
              if (
                index > pbcRowsStartPalletIndex &&
                index <= pbcRowsStartPalletIndex + transitionPallet / 2
              ) {
                pbcList1.push(location);
              } else if (
                index > pbcRowsStartPalletIndex + transitionPallet / 2 &&
                index <= pbcRowsStartPalletIndex + transitionPallet
              ) {
                pbcList2.push(location);
              } else if (
                index > pbcRowsStartPalletIndex + transitionPallet &&
                index <=
                  pbcRowsStartPalletIndex +
                    transitionPallet +
                    transitionPallet / 2
              ) {
                pbcList3.push(location);
              } else if (
                index >
                  pbcRowsStartPalletIndex +
                    transitionPallet +
                    transitionPallet / 2 &&
                index <= pbcRowsStartPalletIndex + transitionPallet * 2
              ) {
                pbcList4.push(location);
              }
            }
          }
        }
      });

      //Combining All Pallet Lists
      if (transitionPallet === null) {
        sectionList.push(blockSingleColumnList);
      } else {
        if (doubleList1.length !== 0) {
          sectionList.push(doubleList1);
        }
        if (doubleList2.length !== 0) {
          sectionList.push(doubleList2);
        }
        if (doubleList3.length !== 0) {
          sectionList.push(doubleList3);
        }
        if (doubleList4.length !== 0) {
          sectionList.push(doubleList4);
        }

        if (pbcList1.length !== 0) {
          sectionList.push(pbcList1);
        }
        if (pbcList2.length !== 0) {
          sectionList.push(pbcList2);
        }
        if (pbcList3.length !== 0) {
          sectionList.push(pbcList3);
        }
        if (pbcList4.length !== 0) {
          sectionList.push(pbcList4);
        }
      }
      //  if locationOrientation is S the first position should start from south and end to north
      const reversedList = [];
      if (locationMetaData.locationOrientation == "S") {
        sectionList.forEach((item) => {
          const temp = item.reverse();
          reversedList.push(temp);
        });

        sectionList = reversedList;
      }
    }
    return sectionList;
  };

  const formatBlockDataStructure = (blockData, type) => {
    /**
     * Group all the locations with key as location name
     */
    let locationGroup = {};
    blockData.forEach((location) => {
      let name;
      if (type === "rack") {
        name = formatName(location.location);
      } else {
        name = location.location;
      }
      if (!locationGroup[name]) {
        locationGroup[name] = [];
      }
      locationGroup[name].push(location);
    });

    if (type === "block") {
      /**
       * Group all the pallets inside location with key as palletPosition as key
       */
      for (let location in locationGroup) {
        let palletGroup = {};
        let currentLocationIterated = locationGroup[location];
        currentLocationIterated.forEach((palletSet) => {
          let pallet = palletSet.palletPosition;
          if (!palletGroup[pallet]) {
            palletGroup[pallet] = [];
          }
          palletGroup[pallet].push(palletSet);
        });
        locationGroup[location] = palletGroup;

        /**
         * Group all the Levels inside Pallets with key as Pallet Level
         */
        for (let pallet in palletGroup) {
          let levelGroup = {};
          palletGroup[pallet].forEach((levelSet) => {
            let level = levelSet.row;
            if (!levelGroup[level]) {
              levelGroup[level] = [];
            }
            levelGroup[level].push(levelSet);
          });
          palletGroup[pallet] = levelGroup;
        }
      }
    }
    return locationGroup;
  };

  const GenerateHTMLBlockIcon = ({
    locationMetaData,
    locationsList,
    type,
    blocksCount,
    racksCount,
    marker,
  }) => {
    if (locationMetaData.totalPositions !== 0) {
      let transitionPallet = locationMetaData.positionTransition;

      let sectionsData = markerSectionGenerator(
        locationsList,
        transitionPallet,
        locationMetaData.doubleRows,
        locationMetaData.pbcRows,
        locationMetaData.totalPositions,
        locationMetaData
      );

      //Find the Location Orientation of the selected location
      let locationOrientationOfThisLocation = getLocationOrientationOfStorage(
        type,
        sectionsData
      );

      //Reverse or keep the list straight according to the location Orientation of the selected location
      let renderList;

      if (type === "rack") {
        //Get Rendering Data according to position orientation
        renderList = getBlockRackDataWithLocationOrientation(
          locationOrientationOfThisLocation,
          sectionsData,
          "rack"
        );
        //Render Double and Single Pallet Rack
        return renderRackPallet(renderList, racksCount);
      } else if (type === "block") {
        //Get Rendering Data according to position orientation
        renderList = getBlockRackDataWithLocationOrientation(
          locationOrientationOfThisLocation,
          sectionsData,
          "block"
        );
        //Check is the block Vertical
        // --RollBack if needed
        let isLocationVertical = findIsLocationVertical(renderList);
        if (locationMetaData.positionOrientation == "N") {
          const temp = sectionsData.pop();
          sectionsData.unshift(temp);
        }
        // --RollBack if needed
        if (sectionsData.length === 1) {
          //Render Single Pallet Block
          return renderPreFilledSingleBlockPallet(
            renderList,
            isLocationVertical,
            blocksCount
          );
        } else {
          //Render Double Pallet Block
          return renderPreFilledDoubleBlockPallet(
            sectionsData,
            isLocationVertical,
            locationMetaData,
            blocksCount,
            marker
          );
        }
      }
    }
  };

  const renderBlockMarkers = useMemo(() => {
    if (plantConfig && mapObject && blockData && blockData.length !== 0) {
      console.log("computing block");
      //   let blockData = blockStorageData;
      let blocksCount = countBlocksUsingLPNs(blockData);
      let formattedBlockData = formatBlockDataStructure(blockData, "block");

      formattedBlockData = Object.values(formattedBlockData);

      return formattedBlockData.map((block, index) => {
        let blockObject = Object.values(block);
        let validateObject = isBlockObjectValid(blockObject);
        if (validateObject) {
          // Metadata of the Location
          let metaData = validateObject;
          let {
            latitude,
            longitude,
            location,
            locationOrientation,
            positionLength,
          } = getMetaDataOfLocation(metaData, "block");

          // Get Block Details from Config
          const { blockStorage } = plantConfig.indoorMap;
          const {
            marker,
            verticalMarker,
            bounds,
            eachItemOffset,
            eastLocationOffset,
            westLocationOffset,
          } = blockStorage;

          // New Algorithm Start
          let convertedStart;
          let convertedPoints;
          let convertedLatLng;
          let convertedPositionObj;

          if (locationOrientation === "E") {
            // East or North Location Blocks
            convertedStart = mapCoordinate(
              longitude - positionLength,
              latitude,
              "block",
              bounds
            );
            convertedPoints = L.point(convertedStart.x, convertedStart.y);
            convertedLatLng =
              mapObject.map.target.layerPointToLatLng(convertedPoints);
            convertedPositionObj = eachItemOffset[location]
              ? getLocationGroupOffset(
                  convertedLatLng,
                  eachItemOffset[location]
                )
              : getLocationGroupOffset(convertedLatLng, eastLocationOffset);
          } else {
            // West or South Location Blocks
            convertedStart = mapCoordinate(
              longitude,
              latitude,
              "block",
              bounds
            );
            convertedPoints = L.point(convertedStart.x, convertedStart.y);
            convertedLatLng =
              mapObject.map.target.layerPointToLatLng(convertedPoints);
            convertedPositionObj = eachItemOffset[location]
              ? getLocationGroupOffset(
                  convertedLatLng,
                  eachItemOffset[location]
                )
              : getLocationGroupOffset(convertedLatLng, westLocationOffset);
          }
          // New Algorithm End

          let className = `pallets-wrapper positionOrientation-${metaData.positionOrientation} locationOrientation-${metaData.locationOrientation}`;
          if (
            getLocalStorageItem("locationCode") === "ALA" &&
            metaData.location?.indexOf("G.W.") > -1
          ) {
            className += " shift-left-2";
          }

          // Set Dynamic Width and Height of Block from Config
          const blockSizeConfig = {
            blockWidth: marker.size.width,
            blockHeight: marker.size.height,
            singleStackWidth: marker.singleStack.size.width,
            singleStackHeight: marker.singleStack.size.height,
          };

          setBlockStorageSize(blockSizeConfig);

          if (verticalMarker) {
            const verticalBlockSizeConfig = {
              blockWidth: verticalMarker.size.width,
              blockHeight: verticalMarker.size.height,
              singleStackWidth: verticalMarker.singleStack.size.width,
              singleStackHeight: verticalMarker.singleStack.size.height,
            };

            setVerticalBlockStorageSize(verticalBlockSizeConfig);
          }

          // Generate Block Icon with Data
          let reactIcon = convertReactComponentToHtmlIcon(
            <GenerateHTMLBlockIcon
              locationMetaData={metaData}
              locationsList={block}
              type="block"
              blocksCount={blocksCount}
              marker={marker}
            />,
            className
          );

          // Render Marker if Position Object is Defined
          if (convertedPositionObj !== undefined) {
            return (
              <Marker
                key={index}
                position={convertedPositionObj}
                icon={reactIcon}
              >
                <Popup>{location}</Popup>
              </Marker>
            );
          }
        }
      });
    }
    // return null;
  }, [mapObject, plantConfig, blockData]);

  return renderBlockMarkers;
};

export default RenderBlockStorage;
