import { useContext, useEffect, useState, useCallback } from "react";
import PalletsInTrailer from "./PalletsInTrailer";
import DockDoorTopView from "./DockDoorTopView";
import {
  findDockDoorPosition,
  getLocalStorageItem,
  getTimeBasedOnUserTime,
} from "../../../util/helper/helperFunctions";
import { AppContext } from "../../../Context/Context";
import { Progress } from "antd";
import minimizeButton from "../../../assets/icons/minimize.png";
import closeButton from "../../../assets/icons/close.png";
import DockDoorStatus from "./DockDoorStatus";
import { getDockDoorDetails } from "./dockDoorAPI";
import { initDockDoorWebSocket } from "./socketConnection";
import "../../../assets/css/DockDoor/dockDoorModal.css";

const noData = "No Data Found";

const defaultDockDoorDetails = {
  ACTIVE: 0,
  ALLOCATED: 0,
  CURRENT_DATE_TIME: new Date().toISOString(),
  DOOR: "",
  LOADTIME: 0,
  PROCESSTIME: 0,
  PROGRESS: 0,
  RELEASED: 0,
  REMAINPALL: 0,
  SHIPMENT: "",
  STATUS: "",
  TYPE: "",
};

const checkIfFalsy = (data) => !data;

const groupDockDoorWithPallets = (rawData) => {
  let groupedDocks = {};
  rawData.map((dock) => {
    if (!groupedDocks[dock.POSITION]) {
      groupedDocks[dock.POSITION] = [];
    }
    groupedDocks[dock.POSITION].push(dock);
  });
  return groupedDocks;
};

const DockDoorPopup = ({ dockData, socket }) => {
  const {
    isMarkerPopupOpen,
    setIsMarkerPopupOpen,
    plantConfig,
    selectedMarkerData,
    minimizedWebObjectsList,
    setMinimizedWebObjectsList,
    setAlertObject,
  } = useContext(AppContext);

  const [dockDoorDetails, setDockDoorDetails] = useState();
  const [isPalletsInDockDoorLoading, setIsPalletsInDockDoorLoading] =
    useState(false);
  const [palletsInDockDoor, setPalletsInDockDoor] = useState({});
  const [isAdditionalDetailsLoading, setIsAdditionalDetailsLoading] =
    useState(false);
  const [additionalDetails, setAdditionalDetails] = useState({
    dockDoorStatus: {},
    palletsInTrailer: [],
  });
  const [minimizeInitiated, setMinimizeInitiated] = useState(false);

  console.log(isAdditionalDetailsLoading, isPalletsInDockDoorLoading);

  const modalCloseHandler = () => {
    document.body.classList.remove("prevent-scroll");
    setIsMarkerPopupOpen(false);
  };

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("dock-door-wrapper-id")) {
      killTimeInterval();
      modalCloseHandler();
    }
  });

  const handleDockDoorTopViewData = () => {
    const dockDoorPosition = findDockDoorPosition(plantConfig);
    let groupedDocks;
    if (dockDoorPosition == "DOWN") {
      const localDockDoor = JSON.parse(JSON.stringify(dockData));
      groupedDocks = groupDockDoorWithPallets(localDockDoor);
      setPalletsInDockDoor(Object.values(groupedDocks).reverse());
    } else {
      groupedDocks = groupDockDoorWithPallets(dockData);
      setPalletsInDockDoor(groupedDocks);
    }
  };

  useEffect(() => {
    // prevents bg scrolling
    document.body.classList.add("prevent-scroll");
    setIsPalletsInDockDoorLoading(true);
    try {
      handleDockDoorTopViewData();
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => setIsPalletsInDockDoorLoading(false), 1500);
    }
  }, []);

  useEffect(() => {
    if (selectedMarkerData) {
      handleDockDoorTopViewData();
    }
  }, [selectedMarkerData]);

  let dockDetailsInterval;

  const killTimeInterval = () => {
    clearInterval(dockDetailsInterval);
    dockDetailsInterval = null;
  };

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Escape") {
      modalCloseHandler();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const fetchDockDoorDetails = async () => {
      try {
        const dockDoorData = await getDockDoorDetails();
        if (dockDoorData) {
          setDockDoorDetails(dockDoorData);
        }

        if (dockData) {
          const dockDoorStatus = {
            lgvInside: dockData[0].LGV_IN_TRAILER,
            doorOpen: dockData[0].DOOR_OPEN,
            trailerAtDoor: dockData[0].TRAILER_AT_DOOR,
            readyForLoading: dockData[0].READY_FOR_LOADING,
            doorOperation: dockData[0].DOOR_IN_MANUAL,
            airbagHold: dockData[0].AIRBAG_HOLD,
            fault: dockData[0].FAULT,
          };
          setAdditionalDetails({
            ...additionalDetails,
            dockDoorStatus,
            palletsInTrailer: dockData,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (isMarkerPopupOpen) {
      const auth = getLocalStorageItem("auth");
      const locationCode = getLocalStorageItem("locationCode");
      const dockDoorTouched = getLocalStorageItem("dockDoorTouched");
      const dockMode = getLocalStorageItem("dockMode");

      if (auth && locationCode && dockDoorTouched) {
        setIsAdditionalDetailsLoading(true);
        fetchDockDoorDetails();
        // Initiate simple/complex socket connection based on dockMode value
        initDockDoorWebSocket(socket, dockMode);
        dockDetailsInterval = setInterval(fetchDockDoorDetails, 4000);
        setTimeout(() => setIsAdditionalDetailsLoading(false), 1500);
      }
    }

    return () => {
      if (dockDetailsInterval) {
        clearInterval(dockDetailsInterval);
      }
      const locationCode = getLocalStorageItem("locationCode");
      const dockMode = getLocalStorageItem("dockMode");
      socket.off(
        `${locationCode}_DOCK_DETAILS_${dockMode ? "COMPLEX" : "SIMPLE"}`
      );
    };
  }, [isMarkerPopupOpen]);

  const minimizeModalHandler = () => {
    let currentDockList = minimizedWebObjectsList.docks;
    if (currentDockList.length < 10) {
      let isDockAvailableInList = false;
      currentDockList.forEach((item) => {
        if (item[0].DOCK == dockData[0].DOCK) {
          isDockAvailableInList = true;
        }
      });
      if (!isDockAvailableInList) {
        currentDockList.push(dockData);
        setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
          ...minimizedWebObjectsList,
          docks: currentDockList,
        }));
        setMinimizeInitiated(true);
        setTimeout(() => {
          modalCloseHandler();
        }, 450);
      } else {
        modalCloseHandler();
      }
    } else {
      setAlertObject({
        type: "warning",
        payload: {
          heading: "Limit reached!",
          description: "Cannot minimize more than 10 Dock Doors..",
        },
      });
    }
  };

  return (
    <div id="dock-door-wrapper-id" className="dock-door-wrapper">
      <div
        className={`dock-door-wrapper-container ${
          minimizeInitiated ? "modal-animation" : ""
        }`}
      >
        <div className="dock-door-details-section">
          <div className="dock-door-details-header">
            <div className="left-detail-title-section">
              <h3>Dock Door Details</h3>
              <div className="modal-action-buttons">
                <div
                  onClick={minimizeModalHandler}
                  className="modal-action-button-wrapper"
                >
                  <img
                    className="modal-action-button-no-height"
                    src={minimizeButton}
                    alt="minimize-button"
                  />
                </div>
                <div
                  onClick={modalCloseHandler}
                  className="modal-action-button-wrapper"
                >
                  <img
                    className="modal-action-button"
                    src={closeButton}
                    alt="close-button"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="dock-door-details-body">
            <div className="dock-door-detail-left-section">
              {dockDoorDetails ? (
                <>
                  <div className="detail-section-container">
                    {[
                      { label: "Door", value: dockDoorDetails?.DOOR },
                      {
                        label: "Shipment",
                        value: dockDoorDetails?.SHIPMENT,
                      },
                      {
                        label: "Snapshot",
                        value: dockDoorDetails?.CURRENT_DATE_TIME,
                      },
                    ].map(({ label, value }) => (
                      <div key={label} className="detail-section">
                        <h4>{label}</h4>
                        <p>
                          {!checkIfFalsy(value)
                            ? label !== "Snapshot"
                              ? value
                              : getTimeBasedOnUserTime(value).format(
                                  "M/D/YYYY h:mm:ss A z"
                                )
                            : noData}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="detail-section-container">
                    {[
                      { label: "Type", value: dockDoorDetails?.TYPE },
                      { label: "Status", value: dockDoorDetails?.STATUS },
                      {
                        label: "Remaining Pallets",
                        value: dockDoorDetails?.REMAINPALL,
                      },
                      {
                        label: "Load Time",
                        value: dockDoorDetails?.LOADTIME,
                      },
                      {
                        label: "Process Time",
                        value: dockDoorDetails?.PROCESSTIME,
                      },
                      {
                        label: "Progress",
                        value: dockDoorDetails?.PROGRESS,
                      },
                      {
                        label: "Allocated Pallets",
                        value: dockDoorDetails?.ALLOCATED,
                      },
                      {
                        label: "Released Pallets",
                        value: dockDoorDetails?.RELEASED,
                      },
                      {
                        label: "Active Pallets",
                        value: dockDoorDetails?.ACTIVE,
                      },
                    ].map(({ label, value }) => (
                      <div key={label} className="detail-section">
                        <h4>{label}</h4>
                        {label !== "Progress" ? (
                          <p>{!checkIfFalsy(value) ? value : noData}</p>
                        ) : (
                          <Progress
                            percent={!checkIfFalsy(value) ? value : 0}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="dock-door-brief-loader">
                  <h4>Fetching Details...</h4>
                  <div className="spinner"></div>
                </div>
              )}
              <DockDoorStatus
                loading={isAdditionalDetailsLoading}
                dockDoorStatus={additionalDetails.dockDoorStatus}
              />
              <PalletsInTrailer
                loading={isAdditionalDetailsLoading}
                palletsInTrailer={additionalDetails.palletsInTrailer}
              />
            </div>

            <DockDoorTopView
              loading={isPalletsInDockDoorLoading}
              dockDoorNumber={dockDoorDetails?.DOOR}
              palletsInDockDoor={palletsInDockDoor}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DockDoorPopup;
