import { useEffect, useState, useContext, useMemo, useRef } from "react";
import L from "leaflet";
import { ImageOverlay, MapContainer, useMap } from "react-leaflet";
import {
  getLocalStorageItem,
  mapCoordinate,
  onDragging,
  onDraggingEnd,
  expireAuthToken,
  getPlantMapImageUrl,
} from "../../util/helper/helperFunctions";
import { AppContext } from "../../Context/Context";
import zoomInIcon from "../../assets/icons/add.png";
import zoomOutIcon from "../../assets/icons/minus.png";
import zoomRefreshIcon from "../../assets/icons/refresh.png";
import YardSummary from "../Modal/YardSummary/YardSummary";
import YardLegendsPopup from "../Modal/YardLegendPopup/YardLegendPopup";
import YardDetails from "../Modal/YardDetails/YardDetails";
import YardDockDoors from "./YardDockDoors";
import { yardLegend } from "../../util/network/apiUrl";
import { getApi } from "../../util/network/getApi";
import { initiateSocketConnection } from "../../socket/socket";
import "../../assets/css/Map/map.css";
import "../../assets/css/YardView/YardView.css";
import { DB_ERROR } from "../../util/helper/constants";

const zoomTransformArr = [
  "scale(1)", // zoom level 1
  "scale(1.2) translate(0)",
  "scale(1.4) translate(0)",
  "scale(1.6) translate(0)",
  "scale(1.8) translate(0)",
  "scale(2) translate(0)", // zoom level 5
  "scale(2.2) translate(0)",
  "scale(2.4) translate(0)",
  "scale(2.6) translate(0)",
  "scale(2.8) translate(0)",
  "scale(3) translate(0)", // zoom level 10
  "scale(3.2) translate(0)",
  "scale(3.4) translate(0)",
  "scale(3.6) translate(0)",
  "scale(3.8) translate(0)",
  "scale(4) translate(0)", // zoom level 15
  "scale(4.2) translate(0)",
  "scale(4.4) translate(0)",
  "scale(4.6) translate(0)",
  "scale(4.8) translate(0)",
  "scale(5) translate(0)", // zoom level 20
];

const YardView = () => {
  const {
    plantConfig,
    appConfig,
    yardSummaryModalIsOpen,
    isYardLegendsPopupOpen,
  } = useContext(AppContext);
  const mapRef = useRef(null);

  const [mapObject, setMapObject] = useState();
  const [mapLoader, setMapLoader] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [summaryData, setSummaryData] = useState(null);
  const [yardData, setYardData] = useState(null);
  const [legendData, setLegendData] = useState(null);
  const [legendColors, setLegendColors] = useState(null);
  const [yardDetailModalData, setYardDetailModalData] = useState(null);
  const [isYardDetailModalOpen, setIsYardDetailModalOpen] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    setSocket(initiateSocketConnection(getLocalStorageItem("locationCode")));
    document.getElementById("yard-view-map-id-reference").style.transform =
      "scale(1)";
  }, []);

  useEffect(() => {
    mapObject && mapObject.map.target.doubleClickZoom.disable();
  }, [mapObject]);

  useEffect(() => {
    if (!mapObject) return;
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 3840) {
        setZoomLevel(4);
      } else if (screenWidth >= 1920) {
        setZoomLevel(1);
      } else {
        resetZoom();
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mapObject]);

  useEffect(() => {
    if (!mapObject) return;
    document.getElementById("yard-view-map-id-reference").style.transform =
      zoomTransformArr[zoomLevel];
  }, [zoomLevel]);

  useEffect(() => {
    async function getLegendData() {
      const legendData = await getApi(yardLegend);
      if (legendData.status === 200 && legendData.data) {
        setLegendData(legendData.data);
        const mergedColors = {};
        legendData.data.forEach(({ colors }) => {
          colors.forEach(({ title, color }) => {
            mergedColors[title] = color;
          });
        });
        setLegendColors(mergedColors);
      } else if (
        legendData.response.status === 401 ||
        legendData?.error === "Authentication Error!"
      ) {
        expireAuthToken();
      }
    }
    getLegendData();
    return () => {};
  }, []);

  // utility to combine the yard objects based on location name and then concatenate the delivery number for them.
  const combineDataBasedOnLocationName = (data) => {
    if (!data) return [];
    const combined = {};

    data?.forEach((item) => {
      if (!combined[item.locationName]) {
        combined[item.locationName] = {
          ...item,
          deliveryNumber: item.deliveryNumber || "",
        };
      } else {
        if (item.deliveryNumber) {
          combined[item.locationName].deliveryNumber += combined[
            item.locationName
          ].deliveryNumber
            ? `, ${item.deliveryNumber}`
            : item.deliveryNumber;
        }
      }
    });

    return Object.values(combined);
  };

  const initiateSocketData = () => {
    socket.on(
      `${getLocalStorageItem("locationCode")}_YARD_CAPACITY_DETAILS`,
      (response) => {
        if (response) {
          if (response.results.length !== 0 || response.results !== DB_ERROR) {
            setSummaryData(response.results[0]);
          }
        }
      }
    );
    //CAR_YARD_OBJECT_DETAILS
    socket.on(
      `${getLocalStorageItem("locationCode")}_YARD_OBJECT_DETAILS`,
      (response) => {
        if (response) {
          if (response.results.length !== 0 || response.results !== DB_ERROR) {
            setYardData(combineDataBasedOnLocationName(response.results));
            setMapLoader(false);
          }
        }
      }
    );
  };

  if (socket) {
    initiateSocketData();
  }

  const resetZoom = () => {
    setZoomLevel(0);
    if (mapRef.current) {
      mapRef.current.setView([0, 0], 0);
    }
  };

  const RenderMap = useMemo(() => {
    const locationCode = getLocalStorageItem("locationCode");
    if (appConfig && mapObject && plantConfig) {
      const img = getPlantMapImageUrl(appConfig, locationCode, "YARD_MAP");
      const {
        yard: {
          mapBg: {
            bounds: { startX, startY, endX, endY },
          },
          bgColor,
        },
      } = plantConfig;
      const convertedCoordinates = mapCoordinate(startX, startY, "yardmap");
      const convertedPoints = L.point(
        convertedCoordinates.x,
        convertedCoordinates.y
      );
      const convertedLatLng = mapObject.map.target.unproject(convertedPoints);

      const convertedCoordinates1 = mapCoordinate(endX, endY, "yardmap");
      const convertedPoints1 = L.point(
        convertedCoordinates1.x,
        convertedCoordinates1.y
      );
      const convertedLatLng1 = mapObject.map.target.unproject(convertedPoints1);

      const overlayBounds = [
        [convertedLatLng.lat, convertedLatLng.lng],
        [convertedLatLng1.lat, convertedLatLng1.lng],
      ];
      return (
        <ImageOverlay
          url={img}
          bounds={overlayBounds}
          backgroundColor={bgColor}
        />
      );
    }
  }, [mapObject, plantConfig, appConfig]);

  // Fix for map centering glitch at 50% and lesser browser zoom level on inital render.
  const RecenterMap = () => {
    const map = useMap();
    useEffect(() => {
      map.invalidateSize();
    }, []);
    return null;
  };

  return (
    <div
      className={`map-container-wrapper plant-${getLocalStorageItem(
        "locationCode"
      )}`}
    >
      <div
        onMouseDown={(event) => onDragging(event)}
        onMouseUp={(event) => onDraggingEnd(event)}
        id="yard-view-map-container-id"
        className="map-container"
      >
        {mapLoader ? (
          <div className="loader">
            <h4>Loading Yard View...</h4>
            <div className="spinner"></div>
          </div>
        ) : null}
        <MapContainer
          id="yard-view-map-id-reference"
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            background: "green",
          }}
          center={[0, 0]}
          zoom={0}
          ref={mapRef}
          maxBoundsViscosity={1.0}
          crs={L.CRS.Simple}
          attributionControl={false}
          scrollWheelZoom={false}
          zoomControl={false}
          disableDoubleClickZoom={false}
          whenReady={(map) => setMapObject({ map })}
        >
          {legendColors && (
            <YardDockDoors
              yardData={yardData}
              legendColors={legendColors}
              mapObject={mapObject}
              setIsYardDetailModalOpen={setIsYardDetailModalOpen}
              setYardDetailModalData={setYardDetailModalData}
            />
          )}
          {RenderMap}
          <RecenterMap />
        </MapContainer>
      </div>

      <div className="zoom-control-center">
        <button
          id="map-zoom-in-btn"
          className="zoom-controls zoom-in-control"
          disabled={zoomLevel >= 20}
          onClick={() => setZoomLevel((zoom) => ++zoom)}
        >
          <img alt="zoom-in-icon" src={zoomInIcon} />
        </button>
        <button
          id="map-zoom-out-btn"
          className="zoom-controls zoom-out-control"
          disabled={zoomLevel < 1}
          onClick={() => setZoomLevel((zoom) => --zoom)}
        >
          <img
            alt="zoom-out-icon"
            style={{ opacity: zoomLevel < 1 ? "0.5" : "1" }}
            src={zoomOutIcon}
          />
        </button>
        <button
          className="zoom-controls zoom-reset-controls"
          disabled={zoomLevel === 0}
          onClick={() => resetZoom()}
        >
          <img
            alt="zoom-refresh-icon"
            style={{ opacity: zoomLevel === 0 ? "0.5" : "1" }}
            src={zoomRefreshIcon}
          />
        </button>
      </div>

      {isYardDetailModalOpen && (
        <YardDetails
          yardData={yardDetailModalData}
          setIsYardDetailModalOpen={setIsYardDetailModalOpen}
        />
      )}
      {yardSummaryModalIsOpen && <YardSummary summaryData={summaryData} />}
      {isYardLegendsPopupOpen && legendData && (
        <YardLegendsPopup legendData={legendData} />
      )}
    </div>
  );
};

export default YardView;
