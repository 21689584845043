import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Context/Context";
import { Tooltip } from "antd";
import closeBtn from "../../../assets/icons/close.png";
import whiteTick from "../../../assets/icons/whitetick.png";
import NonLGVInventoryTable from "../BlockRackTable/BlockRackTable";
import "../../../assets/css/NonLgvModal/nonLgvModalStyles.css";

// convert the name from "C.E.001" to "C.E", "TAC201" to "TAC201"
const transformLocationName = (data) =>
  Object.groupBy(data, ({ location }) =>
    location.split(".").length > 1
      ? location.split(".").slice(0, 2).join(".")
      : location
  );

const NonLgvModal = ({ nonLgvData }) => {
  const { setIsNonLgvModalOpen } = useContext(AppContext);
  const [nonLgvSocketData, setNonLgvSocketData] = useState({});
  const [selectedBlockKey, setSelectedBlockKey] = useState(null);
  const [selectedInventoryData, setSelectedInventoryData] = useState(null);

  useEffect(() => {
    document.body.classList.add("prevent-scroll");
    setNonLgvSocketData(transformLocationName(nonLgvData));
  }, [nonLgvData]);

  useEffect(() => {
    if (Object.keys(nonLgvSocketData).length > 0) {
      setSelectedBlockKey(Object.keys(nonLgvSocketData)[0]);
      setSelectedInventoryData(
        nonLgvSocketData[Object.keys(nonLgvSocketData)[0]]
      );
    }
  }, [nonLgvSocketData]);

  const closeModalHandler = () => {
    setIsNonLgvModalOpen(false);
    document.body.classList.remove("prevent-scroll");
  };

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("lgv-wrapper-id")) {
      closeModalHandler();
    }
  });

  const lgvListItemHandler = (location, data) => {
    setSelectedBlockKey(location);
    setSelectedInventoryData(data);
  };

  const NonInventoryList = () => {
    return (
      <div className="storage-list">
        {nonLgvSocketData &&
          Object.entries(nonLgvSocketData).map(([location, data]) => (
            <div
              key={location}
              data-testid="non-lgv-list-item"
              className={`storage-list-item${
                selectedBlockKey == location ? " selected" : " unselected"
              }`}
              onClick={() => lgvListItemHandler(location, data)}
            >
              <Tooltip
                placement="right"
                title={location.length > 6 ? location : ""}
                className="flex-center"
              >
                {selectedBlockKey == location && (
                  <img src={whiteTick} alt="check icon" />
                )}
                <p>
                  {location.length > 6
                    ? `${location.slice(0, 6)}...`
                    : location}
                </p>
              </Tooltip>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div id="lgv-wrapper-id" className="popup-wrapper">
      <div className="popup-container">
        <div className="popup-header">
          <div className="popup-title">Non LGV Inventories</div>
          <div className="popup-close">
            <div
              onClick={closeModalHandler}
              className="close-btn"
              id="close-btn"
            >
              <img src={closeBtn} alt="close button" />
            </div>
          </div>
        </div>
        {nonLgvSocketData && Object.keys(nonLgvSocketData).length !== 0 ? (
          <div className="popup-bottom">
            <div className="left-selector-section">
              <div className="vertical-scroller">
                <NonInventoryList />
              </div>
            </div>
            <div className="vertical-scroller">
              {selectedInventoryData && (
                <NonLGVInventoryTable
                  selectedInventoryData={selectedInventoryData}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="popup-bottom flex-center">
            <div className="non-lgv-table-loader">
              <h4>Loading Non-LGV Inventory...</h4>
              <div className="non-lgv-table-spinner"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NonLgvModal;
