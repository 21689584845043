import { DB_ERROR } from "../../../util/helper/constants";

const locationCode = JSON.parse(localStorage.getItem("locationCode"));
const url = (dockMode) =>
  `${locationCode}_DOCK_DETAILS_${dockMode ? "COMPLEX" : "SIMPLE"}`;

export const initDockDoorWebSocket = (socket, dockMode) => {
  if (socket && locationCode) {
    socket.on(url(dockMode), (data) => {
      if (data.results !== DB_ERROR && data.results.length !== 0) return data;
    });
  }
};
