import { useEffect, useState } from "react";
import {
  blockRackTableDataValidation,
  formatTimestamp,
} from "../../../util/helper/helperFunctions";
import "../../../assets/css/BlockRackTable/blockRackTable.css";

const NonLGVInventoryTable = ({ selectedInventoryData }) => {
  const [inventoryData, setInventoryData] = useState(selectedInventoryData);
  console.log({ inventoryData });

  useEffect(() => {
    setInventoryData(selectedInventoryData);
  }, [selectedInventoryData]);

  if (!inventoryData) {
    return (
      <div className="table-loader">
        <h4>Loading...</h4>
        <div className="table-spinner"></div>
      </div>
    );
  }

  return (
    <table
      data-testid="non-lgv-block-table"
      id="block-location-table-block-rack-hof"
    >
      <thead>
        <tr className="block-table-row-block-rack">
          {[
            "LPN",
            "Item",
            "Item Type",
            "Quantity",
            "Lot",
            "Production Date",
            "Expiration Date",
            "Position",
            "Row",
            "Level",
          ]?.map((title, index) => (
            <th
              key={index}
              className={`block-table-header-block-rack ${
                index !== 0 && index !== 9 ? "text-center" : ""
              }`}
            >
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {inventoryData?.map((inventory, index) => (
          <tr key={index} className="block-table-row-block-rack">
            {[
              blockRackTableDataValidation(inventory.lpn),
              blockRackTableDataValidation(inventory.sku),
              blockRackTableDataValidation(inventory.itemType),
              blockRackTableDataValidation(inventory.quantity),
              blockRackTableDataValidation(inventory.lot),
              formatTimestamp(new Date(inventory.productionDate).toUTCString()),
              formatTimestamp(new Date(inventory.productionDate).toUTCString()),
              blockRackTableDataValidation(inventory.position),
              blockRackTableDataValidation(inventory.row),
              blockRackTableDataValidation(inventory.level),
            ]?.map((value, index) => (
              <td
                key={index}
                className={`block-table-data-block-rack ${
                  index !== 0 && index !== 9 ? "text-center" : ""
                }`}
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default NonLGVInventoryTable;
