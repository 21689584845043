const NavQualityPopUp = ({lgvData})=>{

  const dataMapping = [
    { label: "Heading Angle", dataKey: "angle" },
    { label: "Navigation Quality (%)", dataKey: "navQuality" },
    { label: "Reflectors Seen", dataKey: "seenReflectors" },
    { label: "State of Charge (%)", dataKey: "stateOfCharge" },
    { label: "Telescopic Pole Position", dataKey: "telescopicPolePosition" },
  ];

  const RenderLGVData = ({ label, dataKey }) => {
    return (
      <div className="lgv-details" key={dataKey}>
        <h5 className="details-data-title">{label}</h5>
        <h5 className="details-data">{lgvData[dataKey] ?? "null"}</h5>
      </div>
    );
  };

  return (
    <div className="lgv-nav-popup">
      {dataMapping.map(({label,dataKey}) => (
        <RenderLGVData  label={label} dataKey={dataKey}/>
      ))}
    </div>
  );    
}

export default NavQualityPopUp;