import { useEffect, useRef, useState } from "react";
import closeBtn from "../../../assets/icons/close.png";
import locationIcon from "../../../assets/icons/location.png";
import Button from "../../Reusables/Button/Button";
import { icon } from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import SiteSelectionPopup from "../SiteSelectionPopup/SiteSelectionPopup";
import { getLocalStorageItem } from "../../../util/helper/helperFunctions";
import "../../../assets/css/MapModal/mapModal.css";

const MapModal = ({ closeModal, selectedLocation, siteLocations }) => {
  const siteSelectionMap = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [formattedJson, setFormattedJson] = useState();

  const addMarker = (e) => {
    let existingMarkers = markers;
    markers.push(e.latlng);
    setMarkers(existingMarkers);
  };
  useEffect(() => {
    document.body.classList.add("prevent-scroll");
  }, []);

  const locationsJsonFormatter = (data) => {
    let locationsArray = [];
    data.map((jsonValue) => {
      let locationsJson = {
        name: "",
        state: "",
        position: {
          lat: "",
          lng: "",
        },
      };

      Object.entries(jsonValue).forEach(([key, value]) => {
        locationsJson[key] = jsonValue[key];
      });
      locationsJson.position["lat"] = jsonValue.latitude;
      locationsJson.position["lng"] = jsonValue.longitude;
      locationsArray.push(locationsJson);
    });
    return locationsArray;
  };

  useEffect(() => {
    let formattedJson = locationsJsonFormatter(siteLocations);
    setFormattedJson(formattedJson);
  }, [siteLocations]);

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("map-modal-wrapper-id")) {
      closeModal();
      document.body.classList.remove("prevent-scroll");
    }
  });

  const closeModalWrapper = () => {
    closeModal();
    document.body.classList.remove("prevent-scroll");
  };

  const getLocationPlaceholder = () => {
    if (!selectedLocation) {
      return "Select Location";
    } else {
      if (selectedLocation.length !== 0) {
        return getLocalStorageItem("locationCode");
      } else {
        return "Select Location";
      }
    }
  };

  return (
    <div id="map-modal-wrapper-id" className="map-modal-wrapper">
      <div className="map-modal-container">
        <div className="floating-map-options">
          <div className="float-options-left-section">
            <p className="site-selection-title">Site Selection</p>
            <div className="location-selection">
              <p>Selected Site</p>
              <Button
                placeholder={getLocationPlaceholder()}
                icon={locationIcon}
                btnClass={"disable-click"}
              />
            </div>
          </div>
          <div className="float-options-right-section">
            <div onClick={() => closeModalWrapper()} className="close-button">
              <img src={closeBtn} alt="close button" />
            </div>
          </div>
        </div>
        <div className="modal-map-container">
          {formattedJson !== undefined ? (
            <MapContainer
              style={{ height: "100%", width: "100%", borderRadius: "20px" }}
              center={[35, -100]}
              zoom={4}
              zoomControl={false}
              scrollWheelZoom={false}
              attributionControl={false}
              ref={siteSelectionMap}
              onclick={addMarker}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {formattedJson.map((location) => (
                <Marker
                  key={location.code}
                  icon={icon({
                    iconUrl:
                      "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
                    iconSize: [22, 32],
                  })}
                  position={location.position}
                >
                  <Tooltip direction="top" offset={[0, -10]}>
                    {location.code}
                  </Tooltip>
                  <Popup>
                    <SiteSelectionPopup
                      locationDetails={location}
                      selectedLocation={selectedLocation}
                    />
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MapModal;
