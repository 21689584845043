import { useContext, useEffect, useMemo, useState } from "react";
import CheckList from "../../../Reusables/CheckList/CheckList";
import "./lgvCheckList.css";
import Button from "../../../Reusables/Button/Button";
import Arrow from "../../../../assets/icons/arrow.png";
import { formatLgvListToCheckListWithData } from "../../../../util/helper/navHeatmap/navHeatmapHelper";
import { AppContext } from "../../../../Context/Context";

const LgvCheckList = ({ data, allLgv, onChange }) => {
  const { enableCheckList, setEnableCheckList } = useContext(AppContext);
  const [lgvCheckListData, setLgvCheckListData] = useState(null);

  useEffect(() => {
    if (data && allLgv) {
      const formattedLgvCheckList = formatLgvListToCheckListWithData(
        data,
        allLgv
      );
      setLgvCheckListData(
        formattedLgvCheckList.length !== 0 ? formattedLgvCheckList : []
      );
    }
  }, [data, allLgv]);

  const handler = () => {
    setEnableCheckList(!enableCheckList);
  };

  const RenderCheckList = useMemo(() => {
    if (enableCheckList && lgvCheckListData !== null) {
      return <CheckList options={lgvCheckListData} onChange={onChange} />;
    }
  }, [enableCheckList, lgvCheckListData]);

  return (
    <div className="lgv-check-list-container">
      <Button
        placeholder="LGVs"
        btnClass="reverse-content box-shadow"
        imgClass="reverse-content-img"
        icon={Arrow}
        onClick={handler}
      />
      {RenderCheckList}
    </div>
  );
};

export default LgvCheckList;
