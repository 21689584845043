import axios from "axios";
import {
  BLOCK_STORAGES_TIMEMACHINE_URL,
  DOCKDOOR_TIMEMACHINE_URL,
  LGV_TIMEMACHINE_URL,
  MAX_TIMESTAMP_TIMEMACHINE_URL,
  navHeatmapLevel2,
  navHeatmapLevel3,
  PRODUCTION_LINES_TIMEMACHINE_URL,
  RACK_STORAGES_TIMEMACHINE_URL,
} from "./apiUrl";
import {
  apiHeadersFormatter,
  getLocalStorageItem,
} from "../helper/helperFunctions";
import { getFeatureApiBody } from "../helper/navHeatmap/navHeatmapHelper";

/**Function - To Validate all necessary props for API
 * Inputs - Config
 *         1.Plant Code - String
 *         2.Timestamp - Object
 *         3.Feature - string
 *            Feature options
 *              1.LGV_MOVEMENTS
 *              2.DOCK_DOOR
 *              3.PRODUCTION_LINES
 *              4.BLOCK_STORAGE
 *              5.RACK_STORAGE
 *              6.LEVEL_2
 *              7.LEVEL_3
 * Output - Boolean
 */
const validateConfig = (config) => {
  if (config) {
    const { plantCode, apiEndPoint, fromTime, toTime, accessToken } = config;
    if (
      plantCode !== undefined &&
      fromTime !== undefined &&
      toTime !== undefined &&
      apiEndPoint !== undefined &&
      accessToken !== undefined
    ) {
      if (
        plantCode.length !== 0 &&
        fromTime.length !== 0 &&
        toTime.length !== 0 &&
        apiEndPoint.length !== 0 &&
        accessToken.length !== 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**Function - To get the databricks data for Heatmap and Time Machine Feature
 * Input - Config (data related to query and feature)
 *         1.Plant Code - String
 *         2.Timestamp - Object
 *         3.apiEndPoint - string
 *            Feature options
 *              1.LGV_MOVEMENTS
 *              2.DOCK_DOOR
 *              3.PRODUCTION_LINES
 *              4.BLOCK_STORAGE
 *              5.RACK_STORAGE
 *              6.LEVEL_2
 *              7.LEVEL_3
 */

export const callDatabricksApi = async (config, feature, apiControllerRef) => {
  if (config && validateConfig(config)) {
    const { apiEndPoint, accessToken } = config;
    try {
      if (apiControllerRef.current) {
        apiControllerRef.current.abort();
      }
      apiControllerRef.current = new AbortController();

      let signal = apiControllerRef.current.signal;
      let body = getFeatureApiBody(feature, config);

      let postApi = await axios.post(
        apiEndPoint,
        body,
        apiHeadersFormatter(accessToken)
        // { signal: signal }
      );
      // console.log("body", body);
      // let postApi = await fetch(apiEndPoint, {
      //   method: "POST",
      //   body: JSON.stringify(body),
      //   headers: apiHeadersFormatter(accessToken).headers,
      //   signal: signal,
      // });

      if (postApi?.status === 200) {
        const { data } = postApi;
        if (data !== undefined && data !== null && data.length !== 0) {
          return data;
        } else {
          return { msg: "FAILED/PENDING", data: null };
        }
      } else {
        return { msg: "FAILED/PENDING", data: null };
      }
    } catch (err) {
      console.log("GET ERR - ", err);
    }
  } else {
    console.log("config error");
  }
};

const callTimemachineDatabricksApi = async (config) => {
  const { plantCode, fromDate, toDate, apiEndPoint, accessToken } = config;
  try {
    const body = {
      whse: plantCode,
      fromDate: fromDate,
      toDate: toDate,
    };

    let postApi = await axios.post(
      apiEndPoint,
      body,
      apiHeadersFormatter(accessToken)
    );

    if (postApi?.status === 200) {
      const { data } = postApi;
      if (data !== undefined) {
        return data;
      }
    } else {
      return { msg: "error", data: postApi };
    }
  } catch (err) {
    console.log("GET ERR - ", err);
  }
};

export const getTimeMachineData = async (
  feature,
  plant,
  fromDate,
  toDate,
  retryCount = 0,
  maxRetries = 10
) => {
  let auth = getLocalStorageItem("auth");
  try {
    if (auth && auth.accessToken && plant) {
      // Determine the endpoint based on the feature
      let endPoint = "";
      switch (feature) {
        case "LGV":
          endPoint = LGV_TIMEMACHINE_URL;
          break; // Added break to ensure only one case is executed
        case "DOCKS":
          endPoint = DOCKDOOR_TIMEMACHINE_URL;
          break; // Added break to ensure only one case is executed
        case "PROD_LINES":
          endPoint = PRODUCTION_LINES_TIMEMACHINE_URL;
          break; // Added break to ensure only one case is executed
        case "RACK_STORAGES":
          endPoint = RACK_STORAGES_TIMEMACHINE_URL;
          break;
        case "BLOCK_STORAGES":
          endPoint = BLOCK_STORAGES_TIMEMACHINE_URL;
          break;
        case "MAX_TIMESTAMP_FINDER":
            endPoint = MAX_TIMESTAMP_TIMEMACHINE_URL;
            break;
        default:
          throw new Error("Invalid feature");
      }

      // Prepare the navConfig
      let navConfig = {
        plantCode: plant,
        fromDate: fromDate,
        toDate: toDate,
        apiEndPoint: endPoint,
        accessToken: auth.accessToken,
      };

      // Call the Databricks API
      let dataResponse = await callTimemachineDatabricksApi(navConfig);

      // If the response status is "SUCCEED", return the data
      if (dataResponse) {
        return dataResponse;
      }

      // If not "SUCCEEDED", retry until max retries are reached
      if (retryCount < maxRetries) {
        // console.log(`Retry attempt ${retryCount + 1}`);
        return getTimeMachineData(
          feature,
          plant,
          fromDate,
          toDate,
          retryCount + 1,
          maxRetries
        );
      } else {
        throw new Error("Max retries reached, data not retrieved successfully");
      }
    }
  } catch (err) {
    // console.log("Get TimeMachine Data Error: ", err);
    throw err; // Re-throw error after logging it
  }
};

/**
 * Function - To get the heatmap data for different levels
 * Inputs - zoom level (number)
 * output - data Object
 */
export const getNavHeatmapData = async (
  plant,
  timestamp,
  feature,
  bounds,
  apiControllerRef
) => {
  let auth = getLocalStorageItem("auth");
  let account = getLocalStorageItem("account");
  try {
    if (auth && account && timestamp) {
      let navConfig = {};
      switch (feature) {
        case "navLevel3":
          if (bounds) {
            navConfig = {
              plantCode: plant,
              fromTime: `${timestamp.fromDate} ${timestamp.fromTime}:00`,
              toTime: `${timestamp.toDate} ${timestamp.toTime}:00`,
              apiEndPoint: navHeatmapLevel3,
              accessToken: auth.accessToken,
              startX: bounds.startX,
              endX: bounds.endX,
              startY: bounds.startY,
              endY: bounds.endY,
            };
          }
          break;
        case "navLevel2":
          navConfig = {
            plantCode: plant,
            fromTime: `${timestamp.fromDate} ${timestamp.fromTime}:00`,
            toTime: `${timestamp.toDate} ${timestamp.toTime}:00`,
            apiEndPoint: navHeatmapLevel2,
            accessToken: auth.accessToken,
          };
          break;
        default:
          navConfig = {
            plantCode: plant,
            fromTime: `${timestamp.fromDate} ${timestamp.fromTime}:00`,
            toTime: `${timestamp.toDate} ${timestamp.toTime}:00`,
            apiEndPoint: navHeatmapLevel2,
            accessToken: auth.accessToken,
          };
          break;
      }

      if (navConfig && feature) {
        let dataResponse = await callDatabricksApi(
          navConfig,
          feature,
          apiControllerRef
        );
        if (dataResponse && dataResponse.data !== null) {
          return dataResponse;
        }
      } else {
        console.log("Config Error");
      }
    }
  } catch (err) {
    console.log("Get Nav Data ERR - ", err);
  }
};
