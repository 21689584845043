import { getApi } from "../../../util/network/getApi";
import { dockDoorBriefStatus } from "../../../util/network/apiUrl";
import {
  apiHeadersFormatter,
  expireAuthToken,
  getLocalStorageItem,
} from "../../../util/helper/helperFunctions";

export const getDockDoorDetails = async () => {
  const auth = getLocalStorageItem("auth");
  const locationCode = getLocalStorageItem("locationCode");
  const selectedDockDoor = getLocalStorageItem("dockDoorTouched");
  const url = `${dockDoorBriefStatus}/${selectedDockDoor}?location=${locationCode}`;

  try {
    const { status, data } = await getApi(
      url,
      apiHeadersFormatter(auth.accessToken)
    );
    if (status === 200) {
      localStorage.setItem("isModalDetailExist", JSON.stringify(true));
      return data;
    }
    if (status == 401) expireAuthToken();
  } catch (err) {
    console.error("Error fetching dock door details:", err);
  }
};
