const OnboardPalletDataSection = ({ lgvData }) => {
  const skuData = lgvData?.sku || lgvData?.sku_1 || lgvData?.sku_2;
  
  const onboardPalletDataDetails = [
    { label: "SKU", value: skuData },
    { label: "LPN 1", value: lgvData.lpn_1 },
    { label: "LPN 2", value: lgvData.lpn_2 },
  ];


  return (
    <div className="popup-section">
      <h4 className="popup-section-title">ONBOARD PALLET DATA</h4>
      <div className="details-section">
        {onboardPalletDataDetails.map(({label,value}) => (
          <div className="details" key={label}>
            <h5 className="details-data-title">{label}</h5>
            <h5 className="details-data">{value ?? "null"}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OnboardPalletDataSection;
