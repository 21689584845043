import React, { useEffect, useState } from "react";
import { Slider } from "antd";
import { timeFromInt } from "time-number";
import moment from "moment-timezone";

import {
  getLocalStorageItem,
  secondToHourMinSecondFormatter,
} from "../../../util/helper/helperFunctions";

import playButton from "../../../assets/icons/play.png";
import pauseButton from "../../../assets/icons/pauseIcon.png";
import forwardButton from "../../../assets/icons/forward15.png";
import backwardButton from "../../../assets/icons/backward15.png";
import downArrow from "../../../assets/icons/downArrow.png";
import upArrow from "../../../assets/icons/upArrow.png";

const TimeMachineV2Controller = ({
  isPlaying,
  setIsPlaying,
  forwarded15Seconds,
  backward15Seconds,
  timeMachineLoader,
  totalSeekPosition,
  currentSeekPosition,
  handleOnChange,
  timeFrame,
}) => {
  const [timeMachineControllerIsOpen, setTimeMachineControllerIsOpen] =
    useState(true);
  const [currentTimeStamp, setCurrentTimeStamp] = useState("");

  const [endTime, setEndTime] = useState("");

  const playPauseTimeMachine = () => {
    setIsPlaying(!isPlaying);
  };

  const convertToUserTime = (
    timeString,
    timeZone = getLocalStorageItem("timeZone")
  ) => {
    let [date, time] = timeString.split("T");
    time = time.split(":");
    time = `${time[0]}:${time[1]}:${time[2].split(".")[0]}`;

    const timeOfPlant = moment.tz(`${date} ${time}`, timeZone);
    return timeOfPlant
      .clone()
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DD HH:mm:ss");
  };

  const getTimeBasedOnUserTime = (currentSeekPosition) => {
    if (timeFrame.length) {
      return convertToUserTime(timeFrame[currentSeekPosition]);
    }
    return "";
  };

  useEffect(() => {
    if (timeFrame.length) {
      setEndTime(convertToUserTime(timeFrame[timeFrame.length - 1]));
    }
  }, [timeFrame]);

  useEffect(() => {
    if (currentSeekPosition && timeFrame) {
      if (timeFrame[currentSeekPosition]) {
        let frame = timeFrame[currentSeekPosition].split("T")[0];
        setCurrentTimeStamp(frame);
      }
    }
  }, [currentSeekPosition]);

  return (
    <>
      {timeMachineControllerIsOpen ? (
        <div className="time-machine-controller-container">
          <div className="time-machine-sections">
            <div className="time-machine-controller-top-section">
              <Slider
                className="time-machine-seek-bar"
                name="timeMachineSeekbar"
                id="timeMachineSeekbarId"
                min={0}
                max={totalSeekPosition}
                value={currentSeekPosition}
                onChange={handleOnChange}
                draggableTrack={false}
                controlSize={20}
                tooltip={{ formatter: secondToHourMinSecondFormatter }}
                railSize={200}
                dotActiveBorderColor="#4461F2"
                dotBorderColor="#4461F2"
                handleActiveColor="#EEEEEE"
                handleColor="#EEEEEE"
                trackBg="#EEEEEE"
                trackHoverBg="#EEEEEE"
              />
            </div>
            <div className="time-machine-controller-bottom-section">
              <div className="time-bar-section">
                {/* <span>{currentTimeStamp} -</span> */}
                {`${getTimeBasedOnUserTime(currentSeekPosition)} / ${endTime} `}
              </div>
              <div className="control-buttons-section">
                <img
                  onClick={backward15Seconds}
                  className={`${
                    timeMachineLoader || "time-machine-fast-forward-btn"
                  }`}
                  src={backwardButton}
                />
                {isPlaying ? (
                  <img
                    src={pauseButton}
                    onClick={playPauseTimeMachine}
                    className={`${
                      timeMachineLoader
                        ? "no-action-allowed"
                        : "time-machine-play-btn"
                    }`}
                  />
                ) : (
                  <img
                    onClick={playPauseTimeMachine}
                    className={`${
                      timeMachineLoader
                        ? "no-action-allowed"
                        : "time-machine-play-btn"
                    }`}
                    src={playButton}
                  />
                )}
                <img
                  onClick={forwarded15Seconds}
                  className={`${
                    timeMachineLoader || "time-machine-fast-forward-btn"
                  }`}
                  src={forwardButton}
                />
              </div>
              <div
                onClick={() =>
                  setTimeMachineControllerIsOpen(!timeMachineControllerIsOpen)
                }
                className="toggle-controller-section"
              >
                <h4>Hide Controls</h4>
                <img src={downArrow} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="small-controller-toggle-container">
          <div
            onClick={() =>
              setTimeMachineControllerIsOpen(!timeMachineControllerIsOpen)
            }
            className="small-controller-toggle-section"
          >
            <img src={upArrow} />
            <h4>Open Controls</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default TimeMachineV2Controller;
